import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './ControlsTable.css'; // Ensure the CSS is correct
import ControlDetailsSidebar from './ControlDetailsSidebar'; // Import ControlDetailsSidebar
import { auth } from '../firebase';
import Loader from './Loader'; // Import Loader
import { useGlobalState } from '../context/GlobalStateContext';
import UnlinkControlModal from './UnlinkControlModal'; // Import UnlinkControlModal
import RelinkControlModal from './RelinkControlModal'; // Import RelinkControlModal
import { collection, query, where, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure the Firestore instance is imported
import EvidenceModal from './EvidenceModal'; // Import EvidenceModal 

const ControlsTable = ({ onOpenDetailView }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedControl, setSelectedControl] = useState(null);
  const [controls, setControls] = useState([]);
  const [filteredControls, setFilteredControls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unlinkModalOpen, setUnlinkModalOpen] = useState(false);
  const [unlinkReason, setUnlinkReason] = useState('');
  const [controlToUnlink, setControlToUnlink] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [activeTab, setActiveTab] = useState('Linked');
  const [searchTerm, setSearchTerm] = useState(''); 
  const [isEvidenceModalOpen, setEvidenceModalOpen] = useState(false); // Add state for EvidenceModal 
  const [relinkModalOpen, setRelinkModalOpen] = useState(false);
  const [relinkReason, setRelinkReason] = useState('');
  const [controlToRelink, setControlToRelink] = useState(null);

  const { clientId, orgId, setOrgId } = useGlobalState();

  const baseUrl = process.env.NODE_ENV === 'production'
    ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'
    : 'http://localhost:8080';

  const fetchControls = useCallback(() => {
    setLoading(true);

    const resolvedClientId = clientId || "Zjg7OBngxNp35Y4bEMSh"; // Default clientId if not provided

    if (resolvedClientId) {
      // Firestore query to get client_controls where client_id matches
      const q = query(
        collection(db, 'client_controls'),
        where('client_id', '==', resolvedClientId)
      );

      // Listen for real-time updates from Firestore
      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const clientControls = snapshot.docs.map(doc => doc.data());

        const controlDetailsPromises = clientControls.map(async (clientControl) => {
          const controlId = clientControl.control_id;

          // Fetch detailed control information from the 'controls' collection
          const controlDoc = await getDocs(query(collection(db, 'controls'), where('control_id', '==', controlId)));
          const controlData = controlDoc.empty ? null : controlDoc.docs[0].data();

          if (controlData) {
            let ownerName = clientControl.owner;
            let ownerId = clientControl.owner;

            // Fetch owner details if available
            if (clientControl.owner && clientControl.owner !== 'unassigned' && clientControl.owner !== 'needs reassignment') {
              const ownerSnapshot = await getDocs(query(collection(db, 'users'), where('id', '==', clientControl.owner)));
              const ownerData = ownerSnapshot.empty ? null : ownerSnapshot.docs[0].data();
              if (ownerData) {
                ownerName = `${ownerData.fname} ${ownerData.lname}`;
                ownerId = ownerData.id;
              }
            }

            // Fetch control mappings (e.g., frameworks)
            const mappingsSnapshot = await getDocs(query(collection(db, 'control_mappings'), where('control_id', '==', controlId)));
            const mappings = mappingsSnapshot.docs.map(doc => doc.data());

            const frameworkIds = [...new Set(mappings.map(mapping => mapping.framework_id))];

            return {
              ...controlData,
              control_id: controlId,
              owner: ownerName,
              ownerId: ownerId,
              source: clientControl.source || 'vayu',
              frameworks: frameworkIds.join(', '),
              clientId: resolvedClientId,
              status: clientControl.status,
              completed_approved: clientControl.completed_approved, // Fetching completed_approved from client_controls
            };
          }
          return null;
        });

        const controlsWithDetails = await Promise.all(controlDetailsPromises);
        const validControls = controlsWithDetails.filter(control => control !== null);
        setControls(validControls);
        setFilteredControls(validControls);
        setLoading(false);
      });

      // Clean up listener on unmount
      return () => unsubscribe();
    }
  }, [clientId, orgId]);

  useEffect(() => {
    fetchControls();
  }, [fetchControls]);

  const handleRowClick = async (control) => {
    if (!control.control_id) {
      console.error('Control ID is missing:', control);
      return;
    }

    // If the selected control is already open in the sidebar, close it
    if (selectedControl && selectedControl.control_id === control.control_id && sidebarOpen) {
      setSidebarOpen(false);
      setSelectedControl(null);
      return;
    }

    try {
      const mappingsResponse = await fetch(`${baseUrl}/api/control-mappings/${control.control_id}`);
      if (!mappingsResponse.ok) {
        throw new Error(`Failed to fetch control mappings, status: ${mappingsResponse.status}`);
      }

      const mappings = await mappingsResponse.json();

      const updatedControl = {
        ...control,
        controlMappings: mappings,
        completed_approved: control.completed_approved, // Ensure this is passed
        evidence_satisfied: control.evidence_satisfied, // Ensure this is passed
      };

      setSelectedControl(updatedControl);
      setSidebarOpen(true); // Ensure sidebar opens
      setEvidenceModalOpen(true); // Open EvidenceModal when control is clicked
    } catch (error) {
      console.error('Error fetching control mappings:', error);
    }
  };

  const handleControlUpdate = (updatedControl) => {
    setControls(prevControls =>
      prevControls.map(control =>
        control.control_id === updatedControl.control_id ? {
          ...control,
          owner: updatedControl.owner,
          ownerId: updatedControl.ownerId,
          completed_approved: updatedControl.completed_approved // Add this line
        } : control
      )
    );
    if (selectedControl && selectedControl.control_id === updatedControl.control_id) {
      setSelectedControl(updatedControl);
    }
  }; 

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
    setSelectedControl(null);
  };

  const handleUnlink = (control) => {
    setControlToUnlink(control);
    setUnlinkModalOpen(true);
  };

  const confirmUnlink = async () => {
    if (controlToUnlink && unlinkReason) {
      try {
        const response = await fetch(`${baseUrl}/api/unlink-control`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            controlId: controlToUnlink.control_id,
            clientId: controlToUnlink.clientId,
            unlinkReason,
          }),
        });

        if (response.ok) {
          setFilteredControls(prevControls =>
            prevControls.map(control =>
              control.control_id === controlToUnlink.control_id ? {
                ...control,
                status: 'unlinked'
              } : control
            )
          );
          setUnlinkModalOpen(false);
          setUnlinkReason('');
          setControlToUnlink(null);
          setDropdownOpen(null); // Close the dropdown after unlink
        } else {
          console.error('Failed to unlink control');
        }
      } catch (error) {
        console.error('Error unlinking control:', error);
      }
    }
  };

  const handleRelink = (control) => {
    setControlToRelink(control);
    setRelinkModalOpen(true);
  };

  const confirmRelink = async () => {
    if (controlToRelink && relinkReason) {
      try {
        const response = await fetch(`${baseUrl}/api/relink-control`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            controlId: controlToRelink.control_id,
            clientId: controlToRelink.clientId,
            relinkReason,
          }),
        });

        if (response.ok) {
          setFilteredControls(prevControls =>
            prevControls.map(control =>
              control.control_id === controlToRelink.control_id ? {
                ...control,
                status: 'active'
              } : control
            )
          );
          setRelinkModalOpen(false);
          setRelinkReason('');
          setControlToRelink(null);
        } else {
          console.error('Failed to relink control');
        }
      } catch (error) {
        console.error('Error relinking control:', error);
      }
    }
  };

  const toggleDropdown = (index) => {
    if (dropdownOpen === index) {
      setDropdownOpen(null);
    } else {
      setDropdownOpen(index);
    }
  };

  const linkedControls = useMemo(() =>
    filteredControls.filter(control => control.status === 'active'),
    [filteredControls]
  );

  const unlinkedControls = useMemo(() =>
    filteredControls.filter(control => control.status === 'unlinked'),
    [filteredControls]
  );

  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value);
    const lowerCaseSearchTerm = e.target.value.toLowerCase();
    const filtered = controls.filter(control =>
      control.friendly_control_name.toLowerCase().includes(lowerCaseSearchTerm) ||
      control.owner.toLowerCase().includes(lowerCaseSearchTerm) ||
      control.source.toLowerCase().includes(lowerCaseSearchTerm) ||
      (control.frameworks && control.frameworks.toLowerCase().includes(lowerCaseSearchTerm))
    );
    setFilteredControls(filtered);
  }, [controls]);

  return (
    <div className="controls-table-container">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="tabs">
            <button
              className={`tab-button ${activeTab === 'Linked' ? 'active' : ''}`}
              onClick={() => setActiveTab('Linked')}
            >
              Linked
            </button>
            <button
              className={`tab-button ${activeTab === 'Unlinked' ? 'active' : ''}`}
              onClick={() => setActiveTab('Unlinked')}
            >
              Unlinked
            </button>
          </div>

          <div className="search-bar">
            <input
              type="text"
              placeholder="Search controls..."
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
          </div>

          {activeTab === 'Linked' && (
            <table className="controls-table">
              <thead>
                <tr>
                  <th>ACTIONS</th>
                  <th>CONTROL</th>
                  <th>OWNER</th>
                  <th>SOURCE</th>
                  <th>FRAMEWORK</th>
                  <th>MONITORS</th>
                  <th>EVIDENCE APPROVED</th> {/* New column */}
                </tr>
              </thead>
              <tbody>
                {linkedControls.map((control, index) => (
                  <tr key={index}>
                    <td style={{ backgroundColor: "#1a1e2e" }}>
                      <div className="actions-menu" style={{ textAlign: "center" }}>
                        <button onClick={() => toggleDropdown(index)} style={{
                          textAlign: "center",
                          background: "transparent",
                          border: "none",
                          color: "#00ffff",
                          fontSize: "20px",
                          fontWeight: "bold",
                          cursor: "pointer",
                          transition: "transform 0.2s ease-in-out",
                          textShadow: "0px 0px 8px rgba(0, 255, 255, 1)",
                        }}
                          onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.2)"}
                          onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1.0)"}>
                          {dropdownOpen === index ? '🔓' : '🔒'}
                        </button>
                        {dropdownOpen === index && (
                          <div className="dropdown-menu">
                            <button onClick={() => handleUnlink(control)} style={{
                              textAlign: "center",
                              background: "linear-gradient(45deg, #0f0c29, #302b63, #24243e)",
                              borderRadius: "8px",
                              padding: "8px",
                              boxShadow: "0px 0px 10px rgba(0, 255, 255, 0.5)",
                              color: "#00ffff",
                              transition: "transform 0.2s ease-in-out",
                              cursor: "pointer"
                            }}
                              onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.1)"}
                              onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1.0)"}>Unlink</button>
                          </div>
                        )}
                      </div>
                    </td>
                    <td onClick={() => handleRowClick(control)}>{control.friendly_control_name}</td>
                    <td>{control.owner}</td>
                    <td>{control.source}</td>
                    <td>
                      {control.frameworks ? (
                        control.frameworks.split(', ').map((framework, i) => (
                          <span key={i} className="framework-badge">{framework}</span>
                        ))
                      ) : (
                        <span className="framework-badge">N/A</span>
                      )}
                    </td>
                    <td><span className="monitor-status">Pending Integration Setup</span></td>
                    <td style={{ backgroundColor: control.completed_approved ? '#d4edda' : 'transparent', textAlign: 'center' }}>
                      {control.completed_approved ? 'Yes' : 'No'}
                    </td>                  
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {activeTab === 'Unlinked' && (
            <table className="controls-table">
              <thead>
                <tr>
                  <th>ACTIONS</th>
                  <th>CONTROL</th>
                  <th>OWNER</th>
                  <th>SOURCE</th>
                  <th>FRAMEWORK</th>
                  <th>MONITORS</th>
                </tr>
              </thead>
              <tbody>
                {unlinkedControls.map((control, index) => (
                  <tr key={index}>
                    <td>
                      <div className="actions-menu" style={{ textAlign: "center" }}>
                        <button onClick={() => handleRelink(control)} style={{
                          textAlign: "center",
                          background: "linear-gradient(45deg, #0f0c29, #302b63, #24243e)",
                          borderRadius: "8px",
                          padding: "8px",
                          boxShadow: "0px 0px 10px rgba(0, 255, 255, 0.5)",
                          color: "#00ffff",
                          transition: "transform 0.2s ease-in-out",
                          cursor: "pointer"
                        }}
                          onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.1)"}
                          onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1.0)"}>Relink</button>
                      </div>
                    </td>
                    <td onClick={() => handleRowClick(control)}>{control.friendly_control_name}</td>
                    <td>{control.owner}</td>
                    <td>{control.source}</td>
                    <td>
                      {control.frameworks ? (
                        control.frameworks.split(', ').map((framework, i) => (
                          <span key={i} className="framework-badge">{framework}</span>
                        ))
                      ) : (
                        <span className="framework-badge">N/A</span>
                      )}
                    </td>
                    <td><span className="monitor-status">Pending Integration Setup</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <UnlinkControlModal
            isOpen={unlinkModalOpen}
            onClose={() => setUnlinkModalOpen(false)}
            onUnlink={confirmUnlink}
            unlinkReason={unlinkReason}
            setUnlinkReason={setUnlinkReason}
            setDropdownOpen={setDropdownOpen}
          />
          <RelinkControlModal
            isOpen={relinkModalOpen}
            onClose={() => setRelinkModalOpen(false)}
            onRelink={confirmRelink}
            relinkReason={relinkReason}
            setRelinkReason={setRelinkReason}
          />
        </>
      )} 

      <ControlDetailsSidebar
        isOpen={sidebarOpen}
        onClose={handleCloseSidebar}
        controlDetails={selectedControl}
        onControlUpdate={handleControlUpdate}
        onOpenDetailView={onOpenDetailView}
        fetchControls={fetchControls}
      />
    </div>
  );
};

export default ControlsTable; 