import React, { useState, useEffect } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import './LeftSidebar.css';
import { useGlobalState } from '../context/GlobalStateContext';

const LeftSidebar = ({
  onHomeClick,
  onDocumentsClick,
  onControlsClick,
  onToDoListClick,
  onVirtualTeamClick,
  onRealTimeRiskProtectionClick,
  onControlLibraryClick,
  onSettingsClick,
  onAccessReviewClick,
  onIncidentReportClick,
  onSystemsHealthClick,
  onMobileDeviceManagementClick,
  onEndpointDetectionAndResponseClick,
  onPatchingClick,
  onAssetManagementClick,
  onClientsClick,
  onAuditorsClick,
  onVayuMissionControlClick,
  onScopingClick,
  onIssuesClick
}) => {
  const [openSections, setOpenSections] = useState({
    Overview: true,
    Report: true,
    Manage: true,
    ITSecurity: true,
    Admin: true,
  });

  const [orgType, setOrgType] = useState(null);
  const [userEmailDomain, setUserEmailDomain] = useState('');

  useEffect(() => {
    const fetchOrgType = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        try {
          const baseUrl =
            process.env.NODE_ENV === 'production'
              ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'
              : 'http://localhost:8080';
          const response = await fetch(
            `${baseUrl}/api/users?email=${currentUser.email}`
          );
          const userData = await response.json();
          const userOrgId = userData[0]?.orgId;

          if (userOrgId) {
            const orgResponse = await fetch(`${baseUrl}/api/orgs/${userOrgId}`);
            const orgData = await orgResponse.json();
            setOrgType(orgData.type);
          }

          // Extract email domain from user's email
          const emailDomain = currentUser.email.split('@')[0];
          setUserEmailDomain(emailDomain);
        } catch (error) {
          console.error('Error fetching organization type:', error);
        }
      }
    };

    fetchOrgType();
  }, []);

  const { setClientId, setOrgId } = useGlobalState();

  const toggleSection = (section) => {
    setOpenSections({
      ...openSections,
      [section]: !openSections[section],
    });
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('User signed out successfully');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleControlsClick = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      try {
        const baseUrl =
          process.env.NODE_ENV === 'production'
            ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'
            : 'http://localhost:8080';
        const response = await fetch(
          `${baseUrl}/api/users?email=${currentUser.email}`
        );
        const userData = await response.json();
        const userOrgId = userData[0]?.orgId;
        if (userOrgId) {
          setOrgId(userOrgId);
          onControlsClick(userOrgId);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  return (
    <aside className="left-sidebar-custom">
      <nav className="left-sidebar-custom-nav">
        <div className="logo-container">
          <img
            onClick={onHomeClick}
            src="/logo.svg"
            alt="Vayu Logo"
            className="logosidebar"
            width={250}
          />
          {/* Version number below the logo */}
          <div style={{ fontSize: '8pt', textAlign: 'center', marginTop: '5px' }}>
            v. 2.0.0
          </div>
        </div>

        <div className="left-sidebar-custom-accordion-section">
          <div
            className="left-sidebar-custom-accordion-title"
            onClick={() => toggleSection('Overview')}
          >
            Overview{' '}
            <span
              className={`chevron ${openSections.Overview ? 'open' : ''}`}
            >
              &#9650;
            </span>
          </div>
          {openSections.Overview && (
            <ul className="left-sidebar-custom-accordion-content">
              <li onClick={onHomeClick}>Home</li>
              <li onClick={onVirtualTeamClick}>Virtual Team</li>
              <li onClick={onDocumentsClick}>Documents</li>
              
              {userEmailDomain.includes('admin') && (
                <li onClick={onRealTimeRiskProtectionClick}>Roles & Permissions Investigator</li>
              )}
              
              <li onClick={onIssuesClick}>Issues</li>
              <li onClick={onToDoListClick}>To-Do List</li>
              <li onClick={handleLogout}>Log Out</li>
            </ul>
          )}
        </div>
      </nav>
    </aside>
  );
};

export default LeftSidebar; 