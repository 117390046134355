import React, { useState, useEffect } from 'react';
import './ComplianceTile.css';
import { collection, query, where, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useGlobalState } from '../context/GlobalStateContext';

const ComplianceTile = ({ title, backgroundColor, progressColor, clientNames, clientId, onClick }) => {
  const [totalControls, setTotalControls] = useState(0);
  const [completedControls, setCompletedControls] = useState(0);
  const [progress, setProgress] = useState(0);
  const { isAuditor } = useGlobalState();

  useEffect(() => {
    const fetchControlData = async () => {
      if (isAuditor) {
        // For auditors, fetch data for all linked clients
        const linkedClientsData = await Promise.all(clientNames.map(async (clientName) => {
          const clientsQuery = query(collection(db, 'orgs'), where('name', '==', clientName));
          const clientSnapshot = await getDocs(clientsQuery);
          if (!clientSnapshot.empty) {
            const clientDoc = clientSnapshot.docs[0];
            return fetchClientControlData(clientDoc.id);
          }
          return { total: 0, completed: 0 };
        }));

        const totals = linkedClientsData.reduce((acc, curr) => ({
          total: acc.total + curr.total,
          completed: acc.completed + curr.completed
        }), { total: 0, completed: 0 });

        setTotalControls(totals.total);
        setCompletedControls(totals.completed);
      } else {
        // For clients, fetch data for the current user's org
        const data = await fetchClientControlData(clientId);
        setTotalControls(data.total);
        setCompletedControls(data.completed);
      }
    };

    fetchControlData();
  }, [isAuditor, clientNames, clientId]);

  useEffect(() => {
    // Calculate the percentage of completed controls
    const percentage = totalControls > 0 ? (completedControls / totalControls) * 100 : 0;
    setProgress(percentage);
  }, [totalControls, completedControls]);

  const fetchClientControlData = async (clientId) => {
    const clientControlsQuery = query(collection(db, 'client_controls'), where('client_id', '==', clientId));
    return new Promise((resolve) => {
      const unsubscribe = onSnapshot(clientControlsQuery, (snapshot) => {
        const controls = snapshot.docs.map(doc => doc.data());
        const total = controls.length;
        const completed = controls.filter(control => control.completed_approved === true).length;
        unsubscribe();
        resolve({ total, completed });
      });
    });
  };

  return (
    <div
      className="compliance-tile"
      style={{ backgroundColor, cursor: onClick ? 'pointer' : 'default' }}
      onClick={onClick || (() => {})}
    >
      <div className="tile-content">
        <div className="tile-header">
          {title === "unknown" ? "Framework: Unknown" : title}
        </div>
        <div className="tile-body">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%`, backgroundColor: progressColor }}></div>
          </div>
          <div className="tile-footer">
            <span>{progress.toFixed(0)}% Controls Complete</span>
            <span>{totalControls} Total</span>
          </div>
          <div className="tile-footer client-badges">
            {clientNames && clientNames.map((client, index) => (
              <span key={index} className="client-badge">Tenant: {client}</span>
            ))}
          </div>
        </div>
      </div>
      {onClick && <div className="tile-chevron">›</div>}
    </div>
  );
};

export default ComplianceTile; 