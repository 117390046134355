import React, { useState, useMemo } from 'react';
import './ControlDetailsSidebar.css';
import OwnerAssignmentModal from './OwnerAssignmentModal';
import { useGlobalState } from '../context/GlobalStateContext'; 
import EvidenceModal from './EvidenceModal'; 

const ControlDetailsSidebar = ({ isOpen, onClose, controlDetails, onControlUpdate, fetchControls }) => { 
  const [isOwnerModalOpen, setIsOwnerModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false); // State to handle sidebar expansion
  const [orgUsers, setOrgUsers] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null); 
  const [isEvidenceModalOpen, setIsEvidenceModalOpen] = useState(false);  
   
  const openEvidenceModal = () => {
    setIsEvidenceModalOpen(true);
  };
  
  const closeEvidenceModal = () => {
    setIsEvidenceModalOpen(false);
  };
  
  const handleSaveEvidence = (evidence) => {
    console.log('Evidence saved:', evidence);
    // Handle saving evidence to your backend or state
  }; 

  const { clientId, orgId } = useGlobalState();

  const toggleExpand = () => {
    openEvidenceModal(); // Trigger modal instead of sidebar expansion
  }; 

  const openOwnerModal = () => {
    setIsOwnerModalOpen(true);
    fetchOrgUsers();
  };

  const closeOwnerModal = () => {
    setIsOwnerModalOpen(false);
    setSelectedOwner(null);
  };

  const baseUrl = process.env.NODE_ENV === 'production'
    ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'
    : 'http://localhost:8080';

  const fetchOrgUsers = async () => {
    const resolvedOrgId = clientId || orgId;
    try {
      if (resolvedOrgId) {
        const response = await fetch(`${baseUrl}/api/orgs/${resolvedOrgId}/users`);
        if (response.ok) {
          const users = await response.json();
          setOrgUsers(users);
        } else {
          console.error('Failed to fetch organization users');
        }
      } else {
        console.error('No valid clientId or orgId available');
      }
    } catch (error) {
      console.error('Error fetching organization users:', error);
    }
  };

  const assignOwner = async () => {
    if (selectedOwner && controlDetails) {
      const resolvedOrgId = clientId || orgId;
      try {
        const response = await fetch(`${baseUrl}/api/assign-control-owner`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            controlId: controlDetails.control_id,
            clientId: resolvedOrgId,
            ownerId: selectedOwner.id,
          }),
        });

        if (response.ok) {
          const updatedControl = {
            ...controlDetails,
            owner: selectedOwner.name,
            ownerId: selectedOwner.id,
          };
          onControlUpdate(updatedControl);
          closeOwnerModal();
        } else {
          console.error('Failed to assign owner');
        }
      } catch (error) {
        console.error('Error assigning owner:', error);
      }
    }
  };

  const dedupedFrameworks = useMemo(() => {
    if (!controlDetails || !controlDetails.controlMappings) return [];

    const frameworkMap = new Map();

    controlDetails.controlMappings.forEach((mapping) => {
      if (!frameworkMap.has(mapping.framework_id)) {
        frameworkMap.set(mapping.framework_id, {
          ...mapping,
          criteria: [mapping.category_id],
        });
      } else {
        const existingMapping = frameworkMap.get(mapping.framework_id);
        if (!existingMapping.criteria.includes(mapping.category_id)) {
          existingMapping.criteria.push(mapping.category_id);
        }
      }
    });

    return Array.from(frameworkMap.values());
  }, [controlDetails]);

  if (!controlDetails) {
    return null;
  }

  return (
    <>
      <div className={`control-details-sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h2>{controlDetails.friendly_control_name || 'No Name'}</h2>
          <div className="header-buttons">
            
            <button className="close-button" onClick={onClose}>&times;</button>
          </div>
        </div>
        <div className="sidebar-content">
        {(controlDetails.control_description ? controlDetails.control_description.replace(/\[?(THE )?COMPANY\]?/g, 'AssetTrack') : 'No Description')}

          {/* ID Section */}
          <div className="sidebar-section row">
            <h3>ID</h3>
            <p>{controlDetails.control_id || 'N/A'}</p>
          </div>

          {/* Source Section */}
          <div className="sidebar-section row">
            <h3>Source</h3>
            <p>{controlDetails.source || 'Vayu'}</p>
          </div>

          {/* Owner Section */}
          <div className="sidebar-section row">
            <h3>Owner</h3>
            <p>{controlDetails.owner || 'Unassigned'}</p>
            <button className="add-owner-button" onClick={openOwnerModal}>
              {controlDetails.owner ? 'Change Owner' : 'Add Owner'}
            </button>
          </div> 

          {/* Evidence Section */}
<div className="sidebar-section row evidence">
  <h3>Evidence&nbsp;&nbsp;</h3>
  <button className="evidence-button-full" onClick={toggleExpand}>
    <span className="evidence-icon">📂</span> {/* Add an icon or cyber artwork */}
    View/Add Evidence
  </button> 
</div>


          {/* Mapped Frameworks Section */}
          <div className="sidebar-section mapped-frameworks">
            <h3>Mapped Frameworks</h3>
            {dedupedFrameworks.length > 0 ? (
              dedupedFrameworks.map((framework, index) => (
                <div key={index} className="framework-mapping">
                  <h4>{framework.framework_id}</h4>
                  <div className="metadata-section">
                    <p><strong>Criteria:</strong> {framework.criteria.join(', ')}</p>
                    <p><strong>Evidence Required:</strong> {framework.evidence_required || 'N/A'}</p>
                    <p><strong>TSC Domain Mapping:</strong> {framework.tsc_domain_mapping || 'N/A'}</p>
                    <p><strong>TSC Domain Mapping Rationale:</strong> {framework.tsc_domain_mapping_rationale || 'N/A'}</p>
                    <p><strong>Points of Focus Mapping:</strong> {framework.points_of_focus_mapping || 'N/A'}</p>
                    <p><strong>Satisfaction Rationale:</strong> {framework.satisfaction_rationale || 'N/A'}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No frameworks mapped.</p>
            )}
          </div>

          {/* Mapped Monitors Section */}
          <div className="sidebar-section mapped-monitors">
            <h3>Mapped Monitors</h3>
            <span className="monitor-status" style={{ padding: '5px', paddingBottom: '18px' }}>
              Pending Integration Setup
            </span>
            <button className="manage-button">Manage</button>
            <table className="monitors-table">
              <tbody>
                {(controlDetails.monitors || []).map((monitor, index) => (
                  <tr key={index}>
                    <td>{monitor}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={index % 2 === 0}
                        onChange={() => console.log(`Checkbox ${index} clicked`)}
                      />
                    </td>
                  </tr>
                ))}
                {controlDetails.monitors && controlDetails.monitors.length > 2 && (
                  <tr>
                    <td colSpan="2"><a href="#">2 more</a></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
      <EvidenceModal
  isOpen={isEvidenceModalOpen}
  onClose={closeEvidenceModal}
  controlDetails={controlDetails}
  onSaveEvidence={handleSaveEvidence}
  refreshTable={fetchControls}
  closeSidebar={onClose}  // Pass onClose to close the sidebar
  onControlUpdate={onControlUpdate}  // Pass onControlUpdate to update control details
/> 
  
      <OwnerAssignmentModal
        isOpen={isOwnerModalOpen}
        onClose={closeOwnerModal}
        orgUsers={orgUsers}
        onAssign={assignOwner}
        selectedOwner={selectedOwner}
        setSelectedOwner={setSelectedOwner}
      />
    </>
  );
};

export default ControlDetailsSidebar; 