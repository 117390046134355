import React, { useState, useEffect } from 'react';
import './RightSidebar.css'; 
import { format, addDays } from 'date-fns';
import { getAuth } from 'firebase/auth'; // Import Firebase Auth to get user info
import { collection, doc, getDoc, getDocs } from 'firebase/firestore'; // Import Firestore methods 
import { db } from '../firebase'; // Import Firestore instance

const RightSidebar = ({ onIssuesClick }) => {
  const [userInfo, setUserInfo] = useState({});
  const [orgInfo, setOrgInfo] = useState({});
  const [linkedClients, setLinkedClients] = useState([]);
  const [emailDomain, setEmailDomain] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user?.uid) {
        setEmailDomain(user.email.split('@')[1]);
        await fetchUserAndOrgData(user.uid);
      }
    };

    fetchData();
  }, []);

 
  const fetchUserAndOrgData = async (userId) => {
    try {
      const userData = await fetchDocumentData('users', userId);
      setUserInfo({
        fname: userData.fname,
        lname: userData.lname,
        role: userData.role,
      });

      const orgData = await fetchDocumentData('orgs', userData.orgId);
      console.log("Full org data:", JSON.stringify(orgData, null, 2)); // Debug: Log full org data
      setOrgInfo({
        name: orgData.name,
        type: orgData.type,
        logo: orgData.logo || 'default_logo.png',
        complianceFrameworks: orgData.complianceFramework || ['Unknown'],
      });

      if (Array.isArray(orgData.linkedClients)) {
        console.log("Linked clients IDs:", orgData.linkedClients);
        const clients = await fetchLinkedClients(orgData.linkedClients);
        setLinkedClients(clients);
      } else {
        console.error("linkedClients is not an array:", orgData.linkedClients);
      }
    } catch (error) {
      console.error("Error fetching user or org data:", error);
    }
  };

  const fetchDocumentData = async (collectionName, docId) => {
    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      console.log(`Fetched ${collectionName} document:`, JSON.stringify(data, null, 2)); // Debug: Log fetched document
      return data;
    }
    throw new Error(`${collectionName} document does not exist`);
  };

  const fetchLinkedClients = async (linkedClientIds) => {
    console.log("Fetching linked clients for IDs:", linkedClientIds);
    const clientsData = await Promise.all(linkedClientIds.map(async (clientId) => {
      try {
        const clientData = await fetchDocumentData('orgs', clientId);
        const teamMembers = await fetchTeamMembers(clientId);
        return {
          id: clientId,
          name: clientData.name,
          logo: clientData.logo || 'default_client_logo.png',
          startDate: clientData.startDate || 'Unknown',
          complianceFramework: clientData.complianceFramework || ['Unknown'],
          cloudPlatform: clientData.cloudPlatform || ['Unknown'],
          teamMembers,
        };
      } catch (error) {
        console.error(`Error fetching client ${clientId}:`, error);
        return createErrorClient(clientId, error.message);
      }
    }));
    console.log("Fetched clients data:", JSON.stringify(clientsData, null, 2)); // Debug: Log all fetched client data
    return clientsData;
  };

  const fetchTeamMembers = async (clientId) => {
    const teamMembersRef = collection(db, 'orgs', clientId, 'teamMembers');
    const teamMembersSnapshot = await getDocs(teamMembersRef);
    return teamMembersSnapshot.docs.map((doc) => doc.data());
  };

  const createErrorClient = (clientId, errorMessage) => ({
    id: clientId,
    name: 'Error Fetching Client',
    logo: 'error_client_logo.png',
    startDate: 'Unknown',
    complianceFramework: ['Unknown'],
    cloudPlatform: ['Unknown'],
    teamMembers: [],
    error: errorMessage
  });

  const handleClientClick = (client) => {
    setSelectedClient(client);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedClient(null);
  }; 

  return (
    <aside className="right-sidebar">
      {/* User Profile Section */}
      <div className="user-profile-card">
        <img src={orgInfo.logo} alt="Organization Logo" className="user-logo" />
        <div className="user-info">
          <h3>{userInfo.fname} {userInfo.lname}</h3>
          <p>{userInfo.role} at {orgInfo.name}</p>
        </div>
      </div>

      {/* Metrics Section */}
      <div className="metrics-section">
        {emailDomain !== 'amitracks.com' && (
          <div className="metric">
            <h4>Active Clients</h4>
            <p className="large-number blue-number">{linkedClients.length}</p>
          </div>
        )}
        <div className="metric">
          <h4>Compliance Frameworks</h4>
          <p>{orgInfo.complianceFrameworks ? orgInfo.complianceFrameworks.join(', ') : 'N/A'}</p>
        </div>
        <div className="metric">
          <h4>Current Issues</h4>
          <a onClick={onIssuesClick}>
            <p className="large-number orange-number" style={{ cursor: 'pointer' }}>0</p>
          </a>
        </div>
      </div>

      {/* Linked Clients as Table View */}
      {emailDomain !== 'amitracks.com' && (
        <div className="client-selector">
          <h4>Linked Clients</h4>
          <table className="client-table">
            <thead>
              <tr>
                <th>Logo</th>
                <th>Name</th>
                <th>Compliance</th>
                <th>Platform</th>
              </tr>
            </thead>
            <tbody>
              {linkedClients.map((client) => (
                <tr key={client.id} onClick={() => handleClientClick(client)} className="client-row">
                  <td><img src={client.logo} alt={`${client.name} Logo`} className="client-logo" /></td>
                  <td>{client.name}{client.error && ' (Error)'}</td>
                  <td>{client.complianceFramework.join(', ')}</td>
                  <td>{client.cloudPlatform.join(', ')}</td>
                </tr>
              ))} 
            </tbody>
          </table>
        </div>
      )}

      {/* Upcoming Events */}
      <div className="events-section">
        <h4>Upcoming Events</h4>
        <div className="calendar-view">
          {Array.from({ length: 6 }).map((_, index) => {
            const today = new Date();
            const currentDay = today.getDay();
            const startDate = currentDay === 0 || currentDay === 6
              ? addDays(today, 8 - currentDay)
              : today;
            const dateToShow = addDays(startDate, index);
            const formattedDateDay = format(dateToShow, 'E');
            const formattedDateDate = format(dateToShow, 'MMM d');

            return (
              <div key={index} className="calendar-time-slot">
                <div className="time-label">{formattedDateDay}<br></br>{formattedDateDate}</div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Selected Client Popup */}
      {showPopup && selectedClient && (
        <div className="popup-overlay">
          <div className="popup-content">
            <img src={selectedClient.logo} alt={`${selectedClient.name} Logo`} className="popup-client-logo" />
            <h2>{selectedClient.name} Information</h2>

            {/* Client Data in Table */}
            <table className="client-info-table">
              <thead>
                <tr>
                  <th>Compliance</th>
                  <th>Platform</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedClient.complianceFramework ? selectedClient.complianceFramework.join(', ') : 'N/A'}</td>
                  <td>{selectedClient.cloudPlatform ? selectedClient.cloudPlatform.join(', ') : 'N/A'}</td>
                </tr>
              </tbody>
            </table>

            {/* Team Members in Table */}
            <h3>Team Members</h3>
            <table className="team-members-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {selectedClient.teamMembers.map(member => (
                  <tr key={member.uid}>
                    <td>{member.fname} {member.lname}</td>
                    <td>{member.role}</td>
                    <td>{member.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <button className="close-popup" onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </aside>
  );
};

export default RightSidebar; 