import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import './ControlsInfo.css';
import { useGlobalState } from '../context/GlobalStateContext';
import { collection, query, where, onSnapshot } from 'firebase/firestore'; // Firebase Firestore imports
import { db } from '../firebase'; // Import Firestore instance

const ControlsInfo = ({ controlType, onCloseSidebar }) => {
  const [assignmentData, setAssignmentData] = useState([]);
  const [statusData, setStatusData] = useState({});
  const [loading, setLoading] = useState(true);

  const { clientId, orgId } = useGlobalState();

  useEffect(() => {
    const resolvedClientId = clientId || orgId || 'Zjg7OBngxNp35Y4bEMSh'; // Default client_id if none found

    if (resolvedClientId) {
      // Firestore query to get client_controls where client_id matches
      const q = query(
        collection(db, 'client_controls'), 
        where('client_id', '==', resolvedClientId)
      );

      // Listen for real-time updates in Firestore
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const clientControls = snapshot.docs.map(doc => doc.data());

        // Count assigned, unassigned, and needs reassignment controls
        const assignedControls = clientControls.filter(control => control.owner && control.owner !== 'unassigned' && control.owner !== 'needs reassignment').length;
        const unassignedControls = clientControls.filter(control => control.owner === 'unassigned').length;
        const needsReassignmentControls = clientControls.filter(control => control.owner === 'needs reassignment').length;

        // Set assignment data
        setAssignmentData([
          { name: 'Assigned', value: assignedControls },
          { name: 'Unassigned', value: unassignedControls },
          { name: 'Needs reassignment', value: needsReassignmentControls },
        ]);

        // Count total controls and completed approved controls
        const totalControls = clientControls.length;
        const approvedControls = clientControls.filter(control => control.completed_approved === true).length;
        const compliancePercentage = totalControls > 0 ? (approvedControls / totalControls) * 100 : 0;

        // Update status data
        setStatusData({
          compliancePercentage: isNaN(compliancePercentage) ? 0 : compliancePercentage,
          totalControls,
          completedControls: approvedControls, // Completed approved controls
        });

        setLoading(false);
      });

      // Clean up listener on unmount
      return () => unsubscribe();
    }
  }, [clientId, orgId]); // Effect will run if clientId or orgId changes

  const COLORS = ['#E0E0E0', '#FF9800', '#7D7AFF'];

  const handleClick = () => {
    // Close the sidebar when this component is clicked
    if (onCloseSidebar) {
      onCloseSidebar();
    }
  };

  return (
    <div className="controls-info-container" onClick={handleClick}>
      {loading ? (
        <div className="cyberpunk-loader">
          <div className="cyberpunk-bar"></div>
        </div>
      ) : (
        <>
          <div className="controls-info-filters">
            <span>Filter By</span>
            <button className="filter-button active">Framework</button>
            <button className="filter-button">Owner</button>
            <button className="filter-button">Source</button>
          </div>
          <div className="controls-info-content">
            <div className="owner-assignment-section">
              <h2>Owner assignment for {controlType}</h2>
              <div className="owner-assignment-chart">
                <ResponsiveContainer width="100%" height={200}>
                  <PieChart>
                    <Pie
                      data={assignmentData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {assignmentData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <div className="chart-center-text">
                  <div className="percentage">
                    {assignmentData.length > 0 && assignmentData.reduce((acc, item) => acc + item.value, 0) > 0
                      ? `${((assignmentData[0].value / assignmentData.reduce((acc, item) => acc + item.value, 0)) * 100).toFixed(0)}%`
                      : '0%'} 
                  </div>
                  <div className="label">Assigned</div>
                </div>
              </div>
              <div className="assignment-details">
                <p><span className="dot orange"></span> Unassigned: {assignmentData[1]?.value || 0}</p>
                <p><span className="dot purple"></span> Needs reassignment: {assignmentData[2]?.value || 0}</p>
              </div>
            </div>
            <div className="status-section">
              <h2>Status</h2>
              <div className="status-details">
                <p className="status-label">Control in Compliance</p>
                <p className="status-percentage">
                  {statusData.compliancePercentage.toFixed(0)}%
                </p>
                <div className="status-bar">
                  <div className="status-progress" style={{ width: `${statusData.compliancePercentage}%` }}></div>
                </div>
                <div className="status-controls-summary">
                  <span>{statusData.completedControls} Controls</span> {/* Completed approved controls */}
                  <span>{statusData.totalControls} Total</span> {/* Total controls */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ControlsInfo; 